import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/privacy"

const PrivacyPolicy = ({ data, location }) => {
  const title = "Polityka prywatności"

  return (
    <Layout location={location} seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content data={data} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo4MDI3" } }) {
      nodes {
        title
        content
      }
    }
  }
`

export default PrivacyPolicy
