import "./styles.scss"

import React from "react"

const Content = ({ data }) => {
  return (
    <section className="privacy-policy-content">
      <div className="container-fluid">
        <div
          dangerouslySetInnerHTML={{
            __html: data?.allWpPage?.nodes[0]?.content,
          }}
        />
      </div>
    </section>
  )
}

export default Content
